@import 'styles/import';

.facility_island {
  position: fixed;
  left: 50%;
  bottom: calc(cRem(48) + cRem(72) + cRem(17));

  transform: translate(-50%);

  height: cRem(36);
  min-width: cRem(98);

  padding: 0 cRem(16);
  gap: cRem(10);
  flex-shrink: 0;

  border-radius: cRem(18);

  border: cRem(1) solid $gray-ea;
  box-shadow: 0px cRem(4) cRem(12) 0px rgba(204, 204, 204, 0.6);

  @include flexCenter;

  &.open {
    bottom: calc(cRem(48) + cRem(72) + cRem(17) + cRem(64));
    animation: slideIn 0.6s forwards;
  }

  &.close {
    bottom: calc(cRem(48) + cRem(72) + cRem(17));
    animation: slideOut 0.6s forwards;
  }

  .facility_island_title {
    text-align: center;
    font-size: cRem(14);
    font-weight: 600;
    line-height: cRem(18);
    letter-spacing: -0.28px;
  }
}

@keyframes slideIn {
  from {
    bottom: calc(cRem(48) + cRem(72) + cRem(17));
  }

  to {
    bottom: calc(cRem(48) + cRem(72) + cRem(17) + cRem(64));
  }
}

@keyframes slideOut {
  from {
    bottom: calc(cRem(48) + cRem(72) + cRem(17) + cRem(64));
  }

  to {
    bottom: calc(cRem(48) + cRem(72) + cRem(17));
  }
}
