@import 'styles/import';

.floor_list_with_location_btn {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: cRem(49);
  flex-shrink: 0;

  padding-left: cRem(20);

  border-top: cRem(1) solid #222;

  overflow-x: auto;
  overflow-y: hidden;

  position: fixed;
  bottom: cRem(72);
  left: 0;

  z-index: 10;

  .floors {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    gap: cRem(20);
    padding-right: cRem(20);

    width: 100%;
    height: 100%;

    overflow-x: auto;

    .floor {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      height: cRem(28);
      padding: 0 cRem(8);

      color: $gray-99;
      text-align: center;
      font-size: cRem(14);
      font-weight: 600;
      line-height: cRem(18);

      position: relative;

      &.active {
        color: $main-black;
      }

      .active_bar {
        position: absolute;
        bottom: 0;

        width: 100%;
        height: cRem(3);
        animation: path 0.5s forwards;
      }

      @keyframes path {
        0% {
          width: 0;
        }

        100% {
          width: 100%;
        }
      }
    }
  }

  .current_btn {
    width: cRem(48);
    height: cRem(48);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    .location_icon {
      width: cRem(28);
      height: cRem(28);
    }
  }
}
