// 한국어, 영어용 폰트
@font-face {
  font-family: 'Pretendard';
  src: url('../fonts/Pretendard-Regular.subset.woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../fonts/Pretendard-Medium.subset.woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Pretendard';
  src: url('../fonts/Pretendard-Bold.subset.woff');
  font-weight: 700;
}
