@import 'styles/import';

.logo_badge {
  z-index: 30;

  width: cRem(54);
  height: cRem(18);

  position: fixed;
  bottom: cRem(137);
  left: cRem(15);

  img {
    width: 100%;
  }
}
