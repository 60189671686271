@import 'styles/import';

.wrapper {
  display: flex;
  flex-direction: column;

  gap: cRem(4);

  .floor_and_point {
    display: flex;
    align-items: center;
  }

  .categories {
    display: flex;
    align-items: flex-start;

    gap: cRem(4);
  }
}

.bar {
  width: cRem(1);
  height: cRem(8);
  margin: 0 cRem(6);
  background: #ccc;
}
