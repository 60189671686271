@import 'styles/import';

.filter {
  width: 100%;
  height: cRem(80);

  display: flex;
  justify-content: flex-start;
  align-items: center;

  gap: cRem(8);
  padding: cRem(16) cRem(24);

  border-bottom: 1px solid #222;
  background: $white;

  .field_wrapper {
    @include flexCenter;
  
    padding: 0 cRem(8);
  
    gap: cRem(4);
  
    width: 100%;
    height: cRem(40);
  
    .search_icon {
      width: cRem(18);
      height: cRem(18);
    }
  
    .search_input {
      width: 100%;
      height: cRem(20);
  
      font-size: cRem(14);
      font-weight: 400;
      letter-spacing: cRem(0.5);
  
      &::placeholder {
        font-size: cRem(14);
        font-weight: 400;
        letter-spacing: cRem(-0.5);
        color: #999;
      }
    }
  }
}
