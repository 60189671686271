@import 'styles/import';

.closed_days {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: cRem(24);
  flex: 1 0 0;

  gap: cRem(4);

  height: cRem(80);

  white-space: nowrap;

  .closed_days_title {
    font-size: cRem(10);
    font-weight: 600;
    line-height: cRem(14);
  }

  .closed_days_content {
    display: grid;
    grid-template-columns: auto 2fr;

    column-gap: cRem(4);

    font-size: cRem(11);
    line-height: 120%;

    height: 100%;

    white-space: nowrap;

    font-style: normal;
    font-weight: 500;

    .closed_day {
      white-space: nowrap;
    }
  }
}
