@import 'styles/import';

.wrapper {
  display: flex;
  align-items: center;

  width: 100%;
  height: cRem(44);
  padding: cRem(11) cRem(20);
  gap: cRem(7);

  position: fixed;
  top: 0;
  left: 0;

  z-index: 50;
  border-bottom: 1px solid rgba(234, 234, 234, 1);

  .badge {
    display: inline-flex;
    padding: cRem(4) cRem(5);
    align-items: flex-start;
    gap: cRem(10);

    white-space: nowrap;

    font-size: cRem(9);
    font-weight: 500;
    line-height: cRem(14);
  }

  .position {
    font-size: cRem(13);
    font-weight: 500;
    line-height: cRem(16);
    letter-spacing: -0.0163rem;

    width: 70%;

    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .btn {
    font-size: cRem(12);
    font-weight: 500;
    line-height: cRem(16);
    letter-spacing: -0.24px;
    text-decoration-line: underline;

    position: absolute;
    right: cRem(20);

    cursor: pointer;
  }
}
