@import 'styles/import';

.select_island {
  position: absolute;
  right: cRem(20);
  bottom: calc(cRem(120) + cRem(72) + cRem(18));

  height: cRem(36);
  min-width: cRem(98);

  padding: 0 cRem(16);
  gap: cRem(10);
  flex-shrink: 0;

  border-radius: cRem(18);

  border: cRem(1) solid $gray-ea;
  box-shadow: 0px cRem(4) cRem(12) 0px rgba(0, 0, 0, 0.2);

  background-color: $white;

  @include flexCenter;

  &.open {
    z-index: 90;
    bottom: auto;

    margin-top: cRem(-67);
  }

  .island_title {
    text-align: center;
    font-size: cRem(14);
    font-weight: 600;
    line-height: cRem(18);
    letter-spacing: -0.28px;
    color: $gray-99;

    &.open {
      color: $gray-44;
    }

    &.close {
      color: $gray-99;
    }
  }
}
