@import 'styles/import';

.body_wrapper {
  display: flex;
  flex-direction: column;

  width: 100%;

  // gap: cRem(24);

  height: calc(cvh(100) - cRem(94) - cRem(72)); // page - header - footer
  overflow-y: auto;

  padding-bottom: cRem(24);

  .category_wrapper {
    width: 100%;
    display: flex;
    padding: 0 cRem(20) cRem(12) cRem(20);
    align-items: flex-start;
    gap: cRem(10);
    align-self: stretch;
    flex-wrap: wrap;
    // border: cRem(1) solid black;
    // height: 100%;

    .category {
      display: flex;
      padding: cRem(4) cRem(8);
      justify-content: center;
      align-items: center;
      border-radius: cRem(4);
      border: cRem(1) solid #999;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      color: #777;

      /* h5_500 */
      font-size: cRem(12);
      font-style: normal;
      font-weight: 500;
      line-height: cRem(16); /* 133.333% */
      letter-spacing: cRem(-0.24);
    }
  }

  .image_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: cRem(20);

    width: 100%;

    padding: 0 cRem(20) cRem(12) cRem(20);
    // padding: 0 cRem(20);

    flex-shrink: 0;

    .thumbnail {
      @include flexCenter;

      width: 100%;
      height: cRem(246);

      background-color: $gray-cc;

      border-radius: cRem(16);
      overflow-x: hidden;

      > img {
        width: 100%;
        height: 100%;

        object-fit: cover;
      }
    }
  }

  .text_details_wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 cRem(24);

    .desc_wrapper {
      display: flex;
      align-items: center;
      margin-top: cRem(24);
      padding: cRem(16);
      background-color: $gray-f9;

      .desc {
        padding-right: cRem(12);
        color: $gray-44;
        font-size: cRem(14);
        font-weight: 400;
        line-height: cRem(20);
        white-space: pre-wrap;
      }
    }
  }
  .custom_field_wrapper {
    margin-top: cRem(24);
  }
}
