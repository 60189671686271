.custom-transition-enter {
  transform: translateX(100%);
}

.custom-transition-enter-active {
  z-index: 1;
  transform: translateX(0);
  transition: transform 300ms ease-in-out;

  box-shadow: -5px 0px 25px rgba(0, 0, 0, 0.05);
}
