@import 'styles/import';

.wrapper {
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: cRem(4);
  flex: 1 0 0;

  height: cRem(64);
  padding: cRem(12) cRem(20);

  border-radius: cRem(12);
  background: #f1f1f1;

  .title {
    color: $gray-77;
    font-size: cRem(12);
    font-style: normal;
    font-weight: 600;
    line-height: cRem(18); /* 128.571% */
    letter-spacing: -0.28px;
  }

  .info_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    .empty {
      color: var(--Text-GrayBF, #bfbfbf);
      font-size: cRem(11);
      font-style: normal;
      font-weight: 500;
      line-height: cRem(18); /* 163.636% */
      letter-spacing: -0.11px;
    }

    .content {
      color: $main-black;
      font-size: cRem(14);
      font-style: normal;
      font-weight: 500;
      line-height: cRem(18); /* 128.571% */
      letter-spacing: -0.28px;
    }

    .floor_and_point {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      gap: cRem(4);
    }
  }
}
