@import 'styles/import';

.bookmark_shortcut_button {
  cursor: pointer;

  width: cRem(48);
  height: cRem(48);

  border-radius: 100%;

  > img {
    height: 100%;
  }
}
