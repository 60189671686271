@import 'styles/import';

.wrapper {
  width: 100%;
  height: cRem(72);

  display: flex;
  justify-content: center;
  align-items: center;

  color: $white;
  text-align: center;
  font-size: cRem(15);
  font-style: normal;
  font-weight: 600;
  line-height: cRem(24);
  text-transform: capitalize;

  gap: cRem(4);

  flex: 5;

  .icon {
    width: cRem(24);
    height: cRem(24);

    > img {
      width: 100%;
    }
  }

  &.detail_btn {
    background-color: $main-black;
  }
}
