@import 'styles/import';

.background {
  position: fixed;
  top: 0;
  left: 0;

  z-index: 100;

  @include flexCenter;

  width: 100vw;
  height: cvh(100);

  background-color: rgba($color: #000000, $alpha: 0.6);

  .popup {
    width: cRem(320);
    height: cRem(184);

    background-color: $white;

    border-radius: cRem(20);

    padding-top: cRem(28);
    padding: cRem(40);

    @include flexColumnCenter;

    gap: cRem(28);

    .text {
      color: $gray-44;
      font-size: cRem(14);
      font-weight: 600;
      line-height: cRem(16);
    }

    .transports_wrapper {
      @include flexCenter;

      gap: cRem(12);

      .transport {
        width: cRem(72);
        height: cRem(72);

        @include flexColumnCenter;

        border-radius: cRem(8);

        gap: cRem(4);

        .icon {
          width: cRem(28);
          height: cRem(28);
        }

        .transport_text {
          color: $white;
          font-size: cRem(11);
          font-weight: 400;
          line-height: cRem(16);
          letter-spacing: cRem(-0.22);
        }
      }
    }
  }
}
