@import 'styles/import';

.container {
  z-index: 80;

  position: fixed;
  // bottom: cRem(240);
  top: 50%;
  left: 50%;

  transform: translate(-50%);

  height: cRem(50);
  padding: 0 cRem(35);

  border-radius: cRem(10);

  background-color: rgba(0, 0, 0, 0);

  display: none;

  .text {
    color: $white;
    font-weight: 600;
    font-size: cRem(14);
    white-space: nowrap;
  }

  &.open {
    animation: openAni 0.5s forwards;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @keyframes openAni {
      from {
        background-color: rgba(0, 0, 0, 0);
      }
      to {
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
  }
  // TODO 사라지는 애니메이션
  // &.close {
  //   animation: closeAni 0.5s forwards;

  //   @keyframes closeAni {
  //     from {
  //       opacity: 1;
  //     }
  //     to {
  //       opacity: 0;
  //       display: none;
  //     }
  //   }
  // }
}
