@import 'styles/import';

.keyword {
  display: flex;
  height: cRem(36px);

  padding: cRem(8) cRem(16);
  border-radius: cRem(4);
  border: cRem(1) solid #999;

  font-size: cRem(16);
  font-weight: 500;
  letter-spacing: cRem(-0.32);
  color: #777;
  flex-wrap: wrap;
}
