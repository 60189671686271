@import 'styles/import';

.home {
  @include flexColumnCenter;
  gap: cRem(4);
  flex: 1;

  height: 100%;
  text-decoration: none;

  .home_icon {
    height: cRem(18);

    > img {
      height: 100%;
    }
  }

  .home_text {
    font-size: cRem(12);
    font-style: normal;
    font-weight: 500;
    line-height: cRem(14);
    letter-spacing: -0.12px;
  }
}
