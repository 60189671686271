@import 'styles/import';

.container {
  flex: 1;

  height: 100%;

  @include flexColumnCenter;
  text-decoration: none;

  gap: cRem(4);

  padding: cRem(1.5) 0 cRem(4) 0;

  .wrapper {
    @include flexColumnCenter;

    gap: cRem(4);

    .text {
      font-size: cRem(12);
      font-style: normal;
      font-weight: 500;
      line-height: cRem(14);
      letter-spacing: -0.12px;
    }
  }
}
