@import 'styles/import';

.container {
  color: #fff;

  width: cRem(30);
  height: cRem(20);
  padding: cRem(4) cRem(6);
  background-color: #24d38a;

  font-size: cRem(10);
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  white-space: nowrap;

  &.WAIT {
    background-color: $main-black;
  }

  &.CLOSE {
    background-color: $gray-99;
  }
}
