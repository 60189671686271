@import 'styles/import';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: cRem(92);

  padding: cRem(30) cRem(20) cRem(20) cRem(20);

  .header_text_row {
    display: flex;
    justify-content: space-between;

    width: 100%;

    .header_text_wrapper {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
  
      gap: cRem(4);
  
      .title {
        font-weight: 600;
        color: $gray-44;
  
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
  
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        word-break: break-all;
  
        text-align: left;
  
        font-size: cRem(18);
        font-style: normal;
        font-weight: 600;
        line-height: cREm(24); /* 133.333% */
        letter-spacing: -0.36px;
      }
    }
  }
  
}
