@import 'styles/import';

.container {
  width: 100%;
  height: calc(cvh(100) - cRem(80));
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  .board_wrapper {
    padding-top: cRem(20);
    padding-bottom: cRem(80);

    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: cRem(40);
  }
}
