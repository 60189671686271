@import 'styles/import';

.popup_inner_type {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: cRem(4);
  background-color: white;

  > span {
    font-size: cRem(12);
    font-weight: 500;
    line-height: cRem(16);
    color: $gray-44;
    letter-spacing: -0.168px;
  }
}

.btn {
  height: cRem(72);

  gap: cRem(4);
  flex-shrink: 0;

  z-index: 110;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: sans-serif;

  &.disabled {
    flex: 1;
    width: 100%;

    text-align: center;
    font-size: cRem(15);
    font-weight: 600;
    line-height: cRem(24);
    letter-spacing: -0.21px;
  }

  &.list_full {
    flex: 1;
    width: 100%;

    text-align: center;
    font-size: cRem(15);
    font-weight: 600;
    line-height: cRem(24);
    letter-spacing: -0.21px;
  }

  &.popup_small {
    // width: cRem(78);

    flex: 1;
    width: 100%;
    min-width: cRem(78);

    font-size: cRem(12);
    font-style: normal;
    font-weight: 600;
    line-height: cRem(16);
    letter-spacing: -0.168px;
  }
}
