@import 'styles/import';

.buttons {
  display: flex;
  flex: 2;

  height: 100%;

  .button {
    @include flexColumnCenter;

    gap: cRem(4);

    flex: 1;

    height: 100%;

    font-weight: 500;
    text-decoration: none;

    .icon {
      width: cRem(18);
      height: cRem(18);
      > img {
        height: cRem(18);
      }
    }

    .menu_text {
      font-size: cRem(12);
      font-style: normal;
      font-weight: 500;
      line-height: cRem(14);
      letter-spacing: -0.12px;

      position: relative;
    }
  }
}
