$white: #ffffff;
$gray-f9: #f9f9f9;
$gray-ea: #eaeaea;
$gray-eb: #ebebeb;
$gray-d9: #d9d9d9;
$gray-cc: #cccccc;
$gray-12: #121212;
$gray-18: #181818;
$gray-22: #222222;
$gray-44: #444444;
$gray-50: #505050;
$gray-77: #777777;
$gray-99: #999999;
$gray-dark: #060a1b;

// black
$main-black: #272a36;
$blue-black: #404557;

// point
$point-blue: #4869df;
$point-green: #76bba1;

// state
$state-blue: #f2f5ff;
$state-yellow: #fff3d4;

// button
$button-primary-normal: #4f74f9;

$border-black: 0.0625rem solid $main-black;
$border-gray-22: 0.0625rem solid $gray-22;
