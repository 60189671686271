@import 'styles/import';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  // position: absolute;
  top: 0;
  left: 0;

  gap: cRem(16);

  padding-left: cRem(43);
  padding-right: cRem(43);

  .contents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: cRem(16);

    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    .alert_icon {
      display: flex;
      justify-content: center;
      align-items: center;

      width: cRem(52);
      height: cRem(52);

      > img {
        height: 100%;
      }
    }

    .text {
      color: $gray-cc;
      text-align: center;
      font-size: cRem(14);
      font-weight: 500;
      line-height: cRem(18);

      white-space: nowrap;
    }
  }
}
