@import 'styles/import';

.popup {
  position: relative;

  width: cRem(320);
  // height: cRem(202);

  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: cRem(28);
  border: 1px solid black;
  padding: cRem(20) cRem(40) cRem(40) cRem(40);
  border-radius: cRem(8);

  .close_btn {
    position: absolute;
    top: cRem(20);
    right: cRem(20);
    width: cRem(24);
    height: cRem(24);
  }

  .title_wrapper {
    position: relative;
    width: 100%;
    height: cRem(24);

    display: flex;
    align-items: center;
    justify-content: center;

    padding-left: cRem(20);
    padding-right: cRem(12);

    .title {
      color: $gray-44;
      font-size: cRem(14);
      font-weight: 600;
      line-height: cRem(16); /* 114.286% */
      letter-spacing: -0.28px;
    }
  }

  .share_wrapper {
    height: cRem(90);

    display: flex;
    align-items: center;
    justify-content: center;

    gap: cRem(40);

    .button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      gap: cRem(14);

      .btn_icon {
        width: cRem(60);
        height: cRem(60);
        flex-shrink: 0;

        border-radius: 100%;
      }

      .share_title {
        color: $gray-44;
        height: cRem(16);

        /* h5_700 */
        font-size: cRem(12);
        font-weight: 700;
        line-height: cRem(16); /* 133.333% */
        letter-spacing: -0.24px;
      }
    }
  }
}
