@import 'styles/import';

.main {
  height: 100%;
  text-decoration: none;

  flex: 1;

  padding: cRem(1.5) 0 cRem(4) 0;

  .logo {
    width: 100%;
    height: 100%;
    padding: 0 cRem(17);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
