@import 'styles/import';

.wrapper {
  width: 100%;
  height: calc(cvh(100) - cRem(72));

  position: absolute;
  top: 0;
  left: 0;

  z-index: 170;

  @include flexColumnCenter;
  gap: cRem(10);

  .loader {
    width: cRem(24);
    height: cRem(24);
    border: cRem(2) solid $gray-cc;
    border-bottom-color: transparent;
    border-radius: 100%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .text {
    color: $gray-cc;
    font-weight: 600;
    font-size: cRem(14);
  }
}
