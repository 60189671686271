@import 'styles/import';

.time {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: cRem(24);
  flex: 1 0 0;

  height: cRem(80);

  white-space: nowrap;

  // border-right: cRem(1) solid $gray-cc;

  .time_title {
    font-size: cRem(10);
    font-weight: 600;
    line-height: cRem(14);
  }

  .time_content {
    @include flexCenter;

    height: 100%;

    font-size: cRem(11);
    line-height: cRem(24);

    display: flex;
    gap: cRem(8);

    font-style: normal;
    font-weight: 500;
  }
}
