@import 'styles/import';

.wrapper {
  z-index: 201;

  width: 100vw;
  height: cvh(100);

  background-color: $white;

  position: absolute;
  top: 0;
  left: 0;

  display: none;

  @include goForwardAni(goUp, cRem(200), cRem(0));
  @include goForwardAni(goDown, cRem(0), cRem(1000));

  &.visible {
    display: block;
    animation: goUp 0.8s forwards;
  }

  &.hidden {
    display: block;
    animation: slideOut 1s forwards;
  }

  .modal_body {
    @include flexColumnCenter;

    width: 100%;
    height: 100%;

    overflow-y: auto;
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(100%);
  }
}
