@import 'styles/import';

.screensaver {
  width: 100%;
  height: calc(cvh(100) - cRem(72)); // page - footer

  overflow: hidden;

  display: flex;

  .cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  img {
    width: 100%;
    height: 100%;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
