@import 'styles/import';

.container {
  // width: 100%;

  // position: absolute;
  // top: cRem(144);
  // left: 0;

  // padding-bottom: cRem(80);

  display: flex;
  flex-direction: column;

  .list {
    width: 100%;
    // height: auto;
    height: calc(cvh(100) - cRem(56) - cRem(88) - cRem(72)); // page - header - searchField - button

    position: relative;
    // top: calc(cRem(56) + cRem(88));
    top: 0;
    left: 0;

    overflow-y: auto;
  }
}
