@import 'styles/import';

.facility_bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  height: cRem(64);
  flex-shrink: 0;

  z-index: 80;

  position: fixed;
  bottom: calc(cRem(72) + cRem(49));
  left: 0;

  width: 100%;

  overflow-x: hidden;

  display: block;
  animation: showAni 0.5s forwards;

  @keyframes showAni {
    from {
      height: 0;
    }

    to {
      height: cRem(64);
    }
  }

  .facilities {
    display: flex;
    justify-content: flex-start;

    gap: cRem(16);
    padding: 0 cRem(20);

    overflow-x: auto;

    width: 100%;
    height: 100%;

    .facility {
      display: flex;
      align-items: center;
      justify-content: center;

      // width: 100%;
      height: 100%;

      .icon {
        width: cRem(28);
        height: cRem(28);

        img {
          width: 100%;
        }
      }

      .name {
        width: 100%;
        min-width: fit-content;
        height: cRem(28);

        flex-shrink: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        text-align: center;
        font-size: cRem(12);
        font-weight: 500;
        white-space: nowrap;
        padding: cRem(5) cRem(8);

        &.active {
          font-weight: 600;
        }
      }
    }
  }
}
