@import 'styles/import';

.container {
  position: fixed;
  top: 0;
  left: 0;

  z-index: 500;
  width: 100%;
  height: cvh(100);

  @include flexColumnCenter;

  overflow: hidden;

  img {
    height: 100%;
  }

  .dots_loading {
    position: absolute;
    bottom: 23%;

    transform: translate(-50%, 50%);

    .dots {
      position: relative;

      width: cRem(10);
      height: cRem(10);
      border-radius: 50%;
      background-color: $white;
      opacity: 0.7;
      animation: dot-flashing 1s infinite linear alternate;
      animation-delay: 0.5s;

      &::before,
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
      }

      &::before {
        left: cRem(-20);
        width: cRem(10);
        height: cRem(10);
        border-radius: 50%;
        background-color: $white;
        opacity: 0.7;
        animation: dot-flashing 1s infinite alternate;
        animation-delay: 0s;
      }

      &::after {
        left: cRem(20);
        width: cRem(10);
        height: cRem(10);
        border-radius: 50%;
        background-color: $white;
        opacity: 0.7;
        animation: dot-flashing 1s infinite alternate;
        animation-delay: 1s;
      }

      @keyframes dot-flashing {
        0% {
          background-color: $white;
          opacity: 0.7;
        }
        50%,
        100% {
          background-color: $white;
          opacity: 0.2;
        }
      }
    }
  }
}
