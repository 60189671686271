@import 'styles/import';

.details_wrapper {
  .detail_wrapper {
    display: flex;
    align-items: center;
    padding: cRem(8) 0;

    gap: cRem(6);

    border-bottom: cRem(1) solid $gray-d9;

    .detail_key {
      width: cRem(90);
      min-height: cRem(24);

      display: flex;
      align-items: center;

      flex-shrink: 0;

      > span {
        // display: -webkit-box;
        // -webkit-line-clamp: 1;
        // -webkit-box-orient: vertical;

        display: flex;

        font-size: cRem(13);
        font-style: normal;
        font-weight: 700;
        line-height: cRem(18);
        letter-spacing: -0.078px;

        overflow: hidden;
        white-space: normal;
        // text-overflow: ellipsis;
        word-break: break-all;
      }
    }

    .detail_value {
      font-size: cRem(13);
      font-style: normal;
      font-weight: 400;
      line-height: cRem(18);
      letter-spacing: -0.078px;

      // 2줄 이후 말줄임 주석
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      // -webkit-box-orient: vertical;

      white-space: pre-wrap; /* \n을 개행으로 처리 */
      word-wrap: break-word; /* 긴 단어가 있을 경우 자동으로 줄바꿈 */
      word-break: break-all; /* 긴 단어를 강제로 줄바꿈 */
      overflow-wrap: break-word; /* 현대 브라우저에서도 줄바꿈 지원 */
      a {
        white-space: pre-wrap; /* \n을 개행으로 처리 */
        word-wrap: break-word; /* 긴 단어가 있을 경우 자동으로 줄바꿈 */
        word-break: break-all; /* 긴 단어를 강제로 줄바꿈 */
        overflow-wrap: break-word; /* 현대 브라우저에서도 줄바꿈 지원 */
      }
    }
  }
}
