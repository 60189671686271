@import 'styles/import';

.simple_popup {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  z-index: 100;
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100vw;
  height: auto;

  &.open {
    animation: fadeIn 1s forwards;
  }

  &.close {
    animation: fadeOut 1s forwards ease-in;
  }

  @keyframes fadeIn {
    from {
      transform: translateY(200%);
    }
    to {
      transform: translateY(0%);
    }
  }

  @keyframes fadeOut {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(100%);
      animation: ease-in;
    }
    100% {
      animation-timing-function: cubic-bezier(1, 0, 0.8, 0.2);
      transform: translateY(200%);
    }
  }

  .close_btn_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    width: 100%;
    height: 100%;

    padding: 0 cRem(20);

    margin-bottom: cRem(12);
  }

  .popup_wrapper {
    width: 100%;
    height: 100%;

    background-color: $white;

    border-radius: cRem(20) cRem(20) 0 0;
    box-shadow: cRem(8) cRem(5) cRem(25) 0 rgba(0, 0, 0, 0.3);

    display: flex;
    flex-direction: column;
    align-items: center;

    flex-shrink: 0;

    .popup_contents {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      width: 100%;
      min-height: cRem(92);

      // padding: cRem(30) cRem(16) cRem(20) cRem(16);

      margin-bottom: cRem(30);

      flex: 1;

      .close_button {
        position: absolute;
        top: cRem(4);
        right: cRem(10);

        width: cRem(30);
        height: cRem(30);

        > img {
          width: 100%;
        }
      }

      .popup_title {
        padding: cRem(30) cRem(16) cRem(20) cRem(16);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        gap: cRem(4);

        width: 100%;

        .title {
          width: 100%;
          min-height: cRem(18);

          text-align: center;

          color: $gray-22;
          font-size: cRem(18);
          font-weight: 600;

          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          word-break: break-all;
          white-space: normal;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .popup_image_wrapper {
        display: flex;
        width: 100%;
        height: cRem(44);
      }
    }

    .buttons_wrapper {
      width: 100%;

      display: flex;
    }
  }
}
