.disabled_screen {
  z-index: 1000000;
  width: 100vw;
  height: cvh(100);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
