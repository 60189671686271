@import 'styles/import';

.bottom {
  display: flex;
  align-items: center;
  gap: cRem(4);

  .calendar {
    width: cRem(18);
    height: cRem(18);
  }

  .date {
    color: $gray-77;
    font-size: cRem(12);
  }
}
