@import 'styles/import';

.board_wrapper {
  display: flex;
  flex-direction: column;

  gap: cRem(12);

  .title_wrapper {
    @include flexColumnCenter;

    width: cRem(320);
    min-height: cRem(44);

    gap: cRem(6);

    .title_and_badge {
      width: 100%;

      display: flex;
      align-items: flex-start;
      justify-content: center;

      gap: cRem(8);

      .title {
        display: flex;
        align-items: center;

        overflow: hidden;

        min-height: cRem(20);
        text-align: center;

        font-size: cRem(16);
        font-style: normal;
        font-weight: 600;
        line-height: cRem(20); /* 125% */
        letter-spacing: -0.32px;

        word-break: break-all;
      }
    }
  }

  .board_image {
    @include flexColumnCenter;

    width: cRem(320);
    height: cRem(410);

    border-radius: cRem(20);
    background: #fff;

    box-shadow: 0 cRem(4) cRem(20) 0 rgba(0, 0, 0, 0.2);

    > img {
      width: 100%;
      height: 100%;

      object-fit: cover;

      border-radius: cRem(20);
    }
  }
}
