@import 'styles/import';

.background {
  z-index: 300;
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: cvh(100);

  flex-shrink: 0;

  &.dim {
    background-color: rgba($color: #000000, $alpha: 0.6);
  }
}
