@import 'styles/import';

.start_btn {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: cRem(4);

  width: 100%;
  height: cRem(44);

  .button_text {
    color: $gray-44;
    text-align: center;
    font-size: cRem(12);
    font-style: normal;
    font-weight: 500;
    line-height: cRem(16); /* 133.333% */
    letter-spacing: -0.168px;
  }
}

.default_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  height: cRem(72);

  background: $button-primary-normal;

  .button_text {
    color: white;
    text-align: center;
    font-size: cRem(15);
    font-style: normal;
    font-weight: 600;
    line-height: cRem(24); /* 160% */
    letter-spacing: -0.21px;
  }
}
