@import 'styles/import';

.location_header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: cRem(56);

  z-index: 110;

  padding-left: cRem(20);
  padding-right: cRem(16);

  .title {
    font-size: cRem(18);
    font-weight: 600;
    line-height: cRem(24);
    letter-spacing: -0.4px;
  }
}
