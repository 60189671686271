@import 'styles/import';

.button {
  z-index: 100;

  position: absolute;

  right: cRem(12);

  width: cRem(40);
  height: cRem(40);

  padding: cRem(6);

  background-color: #000;

  > img {
    width: 100%;
  }

  &.over_btn {
    bottom: cRem(84);
  }

  &.no_btn {
    bottom: cRem(14);
  }

  &.show {
    animation: showAni 0.2s forwards;

    @keyframes showAni {
      from {
        opacity: 0.4;
      }
      to {
        opacity: 1;
      }
    }
  }
}
