@import 'styles/import';

.background {
  z-index: 110;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  width: 100vw;

  flex-shrink: 0;

  &.outside_dim {
    position: fixed;
    top: 0;
    left: 0;

    height: cvh(100);

    background-color: rgba($color: #000000, $alpha: 0.6);
  }

  &.no_outside_dim {
    position: fixed;
    bottom: 0;
    left: 0;

    height: auto;
  }

  .wrapper {
    &.open {
      display: block;
      animation: slideIn 0.6s forwards;
    }

    &.close {
      display: block;
      animation: slideOut 1s forwards;
    }

    .close_btn_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;

      width: 100%;

      padding: 0 cRem(20);

      margin-bottom: cRem(12);
    }

    .popup_wrapper {
      width: 100vw;
      //   height: cvh(85);
      height: auto;

      background-color: $white;

      border-radius: cRem(20) cRem(20) 0 0;
      box-shadow: cRem(8) cRem(5) cRem(25) 0 rgba(0, 0, 0, 0.3);

      display: flex;
      flex-direction: column;
    }
  }
}

@include slideAni(100%, 120%);
