@import 'styles/import';

:root {
  --color-text: $main-black;
}

#root {
  font-size: 16px;

  accent-color: $point-blue;
}

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  padding: 0;
  touch-action: none;
  user-select: none;

  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: var(--color-text);

  cursor: pointer;

  -webkit-tap-highlight-color: transparent;

  font-family: 'Pretendard', Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue',
    'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', sans-serif;

  &::-webkit-scrollbar {
    width: cRem(6);
    height: cRem(0);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-cc;
    border-radius: cRem(3);
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

button {
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
}

input,
input:focus,
input::placeholder {
  outline: none;
  border: none;
  font-size: 1rem;
}

.scroll {
  touch-action: pan-y;
}
