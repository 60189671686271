@import 'styles/import';

.map {
  // flex: 1;
  width: 100%;

  position: fixed;
  left: 0;
  top: 0;

  overflow: hidden;

  &.multi_points {
    top: calc(cRem(44) + cRem(48));
    height: calc(cvh(100) - cRem(72) - cRem(44) - cRem(48) - cRem(48));
  }

  &.single_point {
    top: calc(cRem(44));
    height: calc(cvh(100) - cRem(72) - cRem(44) - cRem(48));
  }

  // > div {
  //   max-height: calc(
  //     cvh(100) - cRem(92) - cRem(79) - cRem(120) - cRem(60)
  //   ); // page - header - footer - facilityBar - floorList
  // }

  .bottom_right_buttons {
    z-index: 120;

    position: absolute;
    bottom: cRem(16);
    right: cRem(21);

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: cRem(12);
  }

  .tracking_wrapper {
    z-index: 31;

    position: fixed;
    top: cRem(100);
    left: cRem(15);

    word-break: break-all;

    .tracking_result {
      display: flex;
      gap: 2px;

      .title {
        font-weight: 700;
        font-size: 12px;
      }

      .value {
        font-weight: 500;
        font-size: 11px;

        color: red;
      }
    }
  }

  .tracking {
    z-index: 31;

    position: fixed;
    bottom: cRem(200);
    left: cRem(15);

    font-weight: 500;
    font-size: 11px;

    color: red;

    word-break: break-all;
  }
}

.bookmark_wrapper {
  width: 100vw;
  height: cvh(100);

  position: absolute;
  top: 0;

  z-index: 130;

  transition: all 0.3s ease-out;

  &.open {
    animation: fadeIn 0.6s forwards;

    @keyframes fadeIn {
      0% {
        right: cRem(-200);
      }

      100% {
        right: cRem(0);
      }
    }
  }
}
