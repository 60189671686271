@import 'styles/import';

.sub_titles {
  @include flexCenter;
}

.divider_dot {
  margin: 0 cRem(4);
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  // border: 1px solid black;
  svg {
    width: cRem(3);
    height: cRem(3);
  }
}

.bar {
  width: cRem(1);
  height: cRem(8);
  margin: 0 cRem(6);
  background: #ccc;
}
