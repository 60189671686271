@import 'styles/import';

.gnb {
  @include flexCenter;

  z-index: 10;

  position: fixed;
  bottom: 0;
  left: 0;

  width: 100%;
  height: cRem(72);
}
