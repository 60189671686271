@import 'styles/import';

.wrapper {
  position: relative;
  display: flex;

  width: 100%;
  padding: cRem(16) cRem(20);
  align-items: center;
  gap: cRem(16);

  border-bottom: cRem(1) solid $gray-cc;

  background-color: $white;

  .bookmark_icon_wrapper {
    position: absolute;
    right: cRem(20);
    top: 0;
  }

  .thumbnail {
    width: cRem(72);
    height: cRem(72);

    flex-shrink: 0;

    > img {
      width: 100%;
      height: 100%;

      object-fit: cover;

      border-radius: cRem(8);

      border: cRem(1) solid $gray-ea;
    }
  }

  .detail_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    height: cRem(72);

    .title {
      width: 100%;
      height: 100%;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      overflow: hidden;
      white-space: normal;
      text-overflow: ellipsis;
      word-break: break-all;

      font-size: cRem(14);
      font-style: normal;
      font-weight: 600;
      line-height: cRem(16); /* 128.571% */
      letter-spacing: -0.28px;
    }
  }
}
