@import 'styles/import';

.action_loading {
  z-index: 170;

  position: absolute;
  top: 0;
  left: 0;

  width: 100vw;
  // height: calc(cvh(100) - cRem(72));
  height: calc(cvh(100));

  background-color: rgba(0, 0, 0, 0.3);

  @include flexColumnCenter;

  color: $white;

  gap: cRem(20);

  .gif_wrapper {
    width: cRem(48);
    height: cRem(46);

    img {
      height: 100%;
    }

    .loading_text {
      font-size: cRem(14);
      font-weight: 500;
    }
  }
}
