@import 'styles/import';

.booth {
  font-size: cRem(12);
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  display: flex;
  align-items: center;
}
