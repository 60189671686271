@import 'styles/import';

.container {
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 50%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 70%;
  width: 100%;
  height: cRem(80);

  padding: 0 cRem(40);

  background-color: rgba(0, 0, 0, 0.4);

  border-radius: cRem(10);

  gap: cRem(6);

  z-index: 300;

  .icon {
    width: cRem(35);
    height: cRem(35);

    > img {
      width: 100%;
      height: 100%;
    }
  }

  > span {
    font-weight: 500;
    font-size: cRem(14);
    line-height: cRem(18);
    display: flex;
    align-items: center;
    color: #ffffff;
    text-align: center;
  }
}
