@import 'styles/import';

.header {
  width: 100%;
  height: cRem(80);

  display: flex;
  justify-content: flex-start;
  align-items: center;

  gap: cRem(8);
  padding: 0 cRem(20);

  background: $white;

  font-size: cRem(20);
  font-weight: 600;

  .arrow {
    width: cRem(40);
    height: cRem(40);
  }
}
