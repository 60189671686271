@import 'styles/import';

.container {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: calc(cvh(100) - cRem(72)); // page - footer

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .list_wrapper {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .menu_list {
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: cRem(68);
        padding-left: cRem(20);

        border-bottom: cRem(1) solid $gray-22;

        font-size: cRem(18);
        font-weight: 700;
        line-height: cRem(22); /* 122.222% */
        letter-spacing: -0.36px;

        .logo {
          height: 100%;
          img {
            height: 100%;
          }
        }
      }
      .menus {
        display: flex;
        flex-direction: column;

        padding: cRem(12) 0;

        cursor: pointer;

        .menu {
          text-decoration: none;

          display: flex;
          align-items: center;

          gap: cRem(12);
          padding: cRem(12) cRem(20);

          // 글자
          color: $main-black;
          font-size: cRem(15);
          font-style: normal;
          font-weight: 500;
          line-height: cRem(18); /* 128.571% */
          letter-spacing: -0.15px;

          .menu_icon {
            width: cRem(18);
            height: cRem(18);

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .more_info_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      gap: cRem(20);

      padding: 0 cRem(20);
    }

    .bottom_wrapper {
      display: flex;
      flex-direction: column;

      .dabeeo {
        display: flex;
        align-items: center;

        height: cRem(68);

        gap: cRem(4);
        padding: cRem(20);

        font-weight: 400;
        font-size: cRem(11);
        line-height: cRem(28);
      }
    }
  }

  .footer {
    display: flex;
  }
}
