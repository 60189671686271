@import 'styles/import';

.popup {
  max-height: cRem(380);
  min-height: cRem(150);
  height: auto;

  word-break: break-all;

  display: flex;
  flex-direction: column;
  align-items: center;

  &.padding {
    padding: cRem(24) cRem(20);
  }

  &.gap {
    gap: cRem(20);
  }

  .message_wrapper {
    &.padding {
      padding: cRem(24) cRem(20);
    }

    display: flex;
    flex-direction: column;
    align-items: center;

    gap: cRem(16);

    width: 100%;
    height: cREm(102);

    .message {
      color: $gray-44;
      text-align: center;
      font-family: 'Pretendard';
      font-size: cRem(16);
      font-style: normal;
      font-weight: 600;
      line-height: cRem(20);
      letter-spacing: -0.32px;
    }
  }

  .sub_btn_wrapper {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    .btn_divider {
      width: cRem(1);
      height: cRem(44);

      background-color: $gray-ea;
    }

    .btn {
      display: flex;
      flex-direction: column;
      align-items: center;

      gap: cRem(4);

      width: 100%;
      height: cRem(44);

      .button_text {
        color: $gray-44;
        text-align: center;
        font-size: cRem(12);
        font-style: normal;
        font-weight: 500;
        line-height: cRem(16); /* 133.333% */
        letter-spacing: -0.168px;
      }
    }
  }
}

.main_btn_wrapper {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    height: cRem(72);

    &.cancel_btn {
      background: $main-black;
    }

    &.save_btn {
      background: $button-primary-normal;
    }

    .button_text {
      color: white;
      text-align: center;
      font-size: cRem(15);
      font-style: normal;
      font-weight: 600;
      line-height: cRem(24); /* 160% */
      letter-spacing: -0.21px;
    }
  }
}
