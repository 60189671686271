@import 'styles/import';

.container {
  width: cRem(320);
  border-right: cRem(1) solid $gray-ea;
  border-bottom: cRem(1) solid $gray-ea;

  display: flex;

  background-color: $white;

  border-bottom: cRem(1) solid $gray-d9;

  .thumb {
    flex-shrink: 0;
    width: cRem(120);
    height: cRem(120);

    img {
      width: 100%;
      height: 100%;
    }
  }

  .details {
    width: cRem(120);
    flex: 1;
    padding: cRem(12);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .top {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: cRem(6);

      .title {
        height: cRem(36);

        text-overflow: ellipsis;
        overflow: hidden;

        font-weight: 600;
        font-size: cRem(14);
        line-height: cRem(18);

        word-break: break-all;

        display: -webkit-box;

        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}
