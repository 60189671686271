@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flexColumnCenter {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin slideAni($from, $to) {
  @keyframes slideIn {
    from {
      transform: translateY($from);
    }

    to {
      transform: translateY(0%);
    }
  }

  @keyframes slideOut {
    from {
      transform: translateY(0%);
    }
    to {
      transform: translateY($to);
    }
  }
}

@mixin goForwardAni($name, $from, $to) {
  @keyframes #{$name} {
    from {
      position: absolute;
      top: $from;
      left: 0;
    }

    to {
      position: absolute;
      top: $to;
      left: 0;
    }
  }
}

// @mixin animation-mixin($name, $from, $to) {
//   @keyframes #{$name} {
//     0% {transform: translate3d($from, 0, 0); opacity: 0;}
//     100% {transform: translate3d($to, 0, 0); opacity: 1;}
//   }
// }
