@import 'styles/import';

.popup {
  // height: calc(cRem(107) + cRem(72));
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  gap: cRem(10);

  .popup_inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    gap: cRem(24);

    padding: cRem(30) cRem(24) cRem(20) cRem(24);

    .info {
      color: $gray-44;
      text-align: center;
      font-family: 'Pretendard';
      font-size: cRem(14);
      font-style: normal;
      font-weight: 400;
      line-height: cRem(20);
      letter-spacing: -0.32px;

      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .origin_and_destination {
      display: flex;
      align-items: center;
      justify-content: center;

      .place {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: cRem(4);
        flex: 1 0 0;

        .title {
          color: var(--Basic-NAVI, #2cc4ce);
          font-family: Pretendard;
          font-size: cRem(12);
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .floor_and_name {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          height: cRem(24);

          gap: cRem(4);

          .floor {
            color: $gray-44;
            font-family: Pretendard;
            font-size: cRem(14);
            font-style: normal;
            font-weight: 400;
            line-height: cRem(16); /* 150% */
            letter-spacing: -0.32px;
          }

          .name {
            color: $gray-44;
            font-family: Pretendard;
            font-size: cRem(16);
            font-style: normal;
            font-weight: 600;
            line-height: cRem(24); /* 150% */
            letter-spacing: -0.32px;

            width: auto;
            max-width: cRem(150);

            overflow: hidden;

            display: -webkit-box;
            -webkit-box-orient: vertical;
            line-clamp: 1;
            -webkit-line-clamp: 1;
          }
        }
      }

      .arrow {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }

  .button_wrapper {
    width: 100%;
    height: cRem(72);

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
