@import 'styles/import';

.container {
  position: absolute;
  left: 50%;
  // bottom: calc(cRem(152) + cRem(48) + cRem(72));
  top: 50%;

  transform: translate(-50%, -50%);

  z-index: 500;

  border-radius: cRem(8);
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0px cRem(4) cRem(15) rgba(153, 153, 153, 0.7);

  display: inline-flex;
  padding: cRem(10) cRem(24);
  justify-content: center;
  align-items: center;
  gap: cRem(10);

  .text {
    color: $gray-cc;
    font-size: cRem(14);
    font-weight: 500;
    line-height: cRem(20); /* 142.857% */
    letter-spacing: -0.28px;

    text-align: center;
    white-space: nowrap;
    // white-space: pre-line;
  }
}
