@import 'styles/import';

.popup {
  width: 100vw;
  height: auto;

  .message_wrapper {
    width: 100%;
    height: cRem(158);

    background-color: $white;
    border-radius: cRem(20) cRem(20) 0px 0px;

    padding: cRem(24) cRem(20);

    display: flex;
    flex-direction: column;

    gap: cRem(16);

    .message {
      color: $button-primary-normal;
      font-family: Pretendard;
      font-size: cRem(18);
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 133.333% */
      letter-spacing: -0.36px;

      white-space: pre-wrap;
    }

    .content_wrapper {
      display: flex;
      flex-direction: column;

      gap: cRem(4);

      height: cRem(46);

      .title {
        color: var(--Text-TEXT_TITLE, #444);
        font-family: Pretendard;
        font-size: cRem(16);
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        letter-spacing: -0.32px;
      }

      .permission_for {
        color: var(--Text-TEXT_ITEM, #999);
        font-family: Pretendard;
        font-size: cRem(14);
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.28px;
      }
    }
  }

  .btn {
    width: 100%;
    height: cRem(72);

    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;

    background: $button-primary-normal;

    color: var(--Floor-FLOOR_TEXT_ACTIVE, #fff);
    text-align: center;
    font-family: Pretendard;
    font-size: cRem(15);
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 160% */
    letter-spacing: -0.21px;
    text-transform: capitalize;
  }
}
