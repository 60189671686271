@import 'styles/import';

.point {
  z-index: 80;

  position: fixed;
  top: cRem(44);
  left: 0;

  width: 100%;

  .options {
    width: 100%;
    max-height: cRem(192);

    overflow-y: auto;

    background-color: #000;

    .option {
      padding: 0 cRem(20);

      height: cRem(48);

      display: flex;
      align-items: center;
      gap: cRem(8);

      color: $gray-77;

      .icon {
        display: flex;
      }
    }
  }

  .current_point {
    display: flex;
    justify-content: space-between;
    align-items: center;

    gap: cRem(8);

    width: 100%;
    height: cRem(48);

    padding: 0 cRem(20);

    z-index: 100;

    color: $white;

    .arrow {
      width: vvw(18);
      height: vvw(18);

      display: flex;

      img {
        height: 100%;
      }
    }

    .current {
      gap: cRem(8);
      display: flex;
      align-items: center;

      .icon {
        display: flex;
      }
    }
  }
}
