@import 'styles/import';

.background {
  z-index: 180;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: cvh(100);

  background-color: rgb(0, 0, 0, 0.8);

  position: absolute;
  top: 0;
  left: 0;

  .container {
    width: 80%;
    height: 20%;

    border-radius: cRem(4);

    background-color: $white;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: cRem(10);

    .text {
      color: $gray-44;
      font-weight: 500;
      font-size: cRem(14);
    }

    .close_btn {
      width: 60%;
      height: cRem(40);

      background-color: $main-black;
      border-radius: cRem(4);

      color: $white;
      font-weight: 600;
    }
  }
}
