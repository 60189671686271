@import 'styles/import';

.layout {
  width: 100vw;
  height: 100%;
  min-height: cvh(100);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
