@import 'styles/import';

.wrapper {
  display: flex;

  z-index: 100;
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100%;
}
