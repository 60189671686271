@import 'styles/import';

.container {
  background-color: $white;
  position: fixed;
  top: 0;

  width: 100%;
  height: cvh(100);

  z-index: 100;

  display: flex;
  flex-direction: column;

  transition: all 0.3s ease-out;

  &.open {
    animation: slideIn 0.6s forwards;
  }

  &.close {
    animation: slideOut 1s forwards;
  }

  @include slideAni(100%, 120%);

  .header {
    padding: cRem(34) cRem(16) cRem(24) cRem(20);

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: cRem(8);

    .close_btn {
      width: cRem(40);
      height: cRem(40);

      img {
        height: 100%;
      }
    }

    .title_wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: cRem(8);

      .title {
        width: 100%;
        height: 100%;

        font-size: cRem(18);
        font-style: normal;
        font-weight: 600;
        line-height: cRem(24); /* 133.333% */
        letter-spacing: -0.36px;

        word-break: break-all;
      }
    }

    .date_wrapper {
      display: flex;
      align-items: center;
      gap: cRem(4);

      .calendar_icon {
        width: cRem(18);
        height: cRem(18);
      }

      .date {
        font-size: cRem(12);
        color: $gray-77;
        font-weight: 500;
      }
    }
  }

  .content {
    flex: 1;
    overflow-y: auto;
    background-color: $gray-f9;
    max-height: 100%;

    img {
      display: flex;
      width: 100%;
    }
  }
}
