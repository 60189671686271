@import 'styles/import';

.keyword_container {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  align-self: stretch;
  flex-wrap: wrap;

  width: 100%;
  padding: cRem(20);

  gap: cRem(10);

  border-bottom: cRem(1) solid $gray-cc;
  background-color: #fff;
}

.keyword_container.noTenant {
  position: absolute;
  z-index: 2;
}
