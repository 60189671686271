@import 'styles/import';

.field_wrapper {
  @include flexCenter;

  // padding-top: cRem(56);

  width: 100%;
  height: cRem(92);
  padding: 0 cRem(20);

  z-index: 110;
}
