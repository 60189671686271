@import 'styles/import';

.multi_lang_wrapper {
  height: cRem(60);
  padding: cRem(16) cRem(20);

  display: flex;
  align-items: center;

  gap: cRem(12);

  .globe_icon {
    width: cRem(18);
    height: cRem(28);
    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    // padding: cRem(2) cRem(7);

    > svg {
      width: 100%;
      height: 100%;
    }
  }

  .languages {
    display: flex;
    align-items: center;

    gap: cRem(12);

    .divider {
      width: cRem(1);
      height: cRem(8);

      background-color: #000;
    }

    .multi_lang {
      padding: cRem(8) 0;
      white-space: nowrap;
      font-size: cRem(13);
      line-height: cRem(16);
      letter-spacing: -0.13px;

      font-weight: 500;

      &.active {
        font-weight: 700;
      }
    }
  }
}
