@import 'styles/import';

.background {
  background-color: rgba($color: #000000, $alpha: 0.6);
  width: 100vw;
  height: cvh(100);
  flex-shrink: 0;

  position: fixed;
  bottom: 0;
  left: 0;

  z-index: 100;

  .wrapper {
    width: 100%;
    max-height: cvh(86.25);

    border-radius: cRem(20) cRem(20) 0 0;

    padding: cRem(20);

    background-color: #fff;

    display: flex;
    flex-direction: column;

    position: fixed;
    bottom: 0;

    @include slideAni(60%, 120%);
    animation: slideIn 0.6s forwards;

    @keyframes slideIn {
      from {
        bottom: cRem(-345);
      }
      to {
        bottom: 0;
      }
    }

    .list {
      overflow-y: auto;

      padding-right: cRem(8);

      .dim {
        width: 100%;
        height: cRem(60);

        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);

        position: absolute;
        bottom: 0;
      }

      .tenant {
        height: cRem(48);
        padding: 0 cRem(20);

        display: flex;
        flex-direction: column;
        justify-content: center;

        border-radius: cRem(8);

        &.active {
          background: $state-blue;
        }

        .tenant_name {
          height: cRem(24);
          line-height: cRem(24);

          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;

          overflow: hidden;
          text-overflow: ellipsis;

          color: $gray-77;
          font-size: cRem(14);

          font-weight: 500;
          line-height: cRem(24);
          letter-spacing: -0.196px;

          &.active {
            color: $button-primary-normal;
            font-weight: 700;
          }
        }
      }
    }
  }
}
