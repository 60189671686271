@import 'styles/import';

.container {
  // 전체 스크롤
  // width: 100%;

  // position: absolute;
  // top: calc(cRem(48) + cRem(92));
  // left: 0;

  // padding-bottom: cRem(80);

  display: flex;
  flex-direction: column;

  height: 100%;

  padding-top: cRem(47);

  .list {
    width: 100%;
    // height: auto;
    height: calc(cvh(100) - cRem(47) - cRem(78) - cRem(72)); // page - locationBar - searchField - footer

    overflow-y: auto;

    position: relative;
    // top: calc(cRem(48) + cRem(80));
    top: cRem(78);
    left: 0;
  }

  .go_up_button {
    z-index: 100;

    position: fixed;

    bottom: cRem(90);
    right: cRem(10);

    width: cRem(60);
    height: cRem(60);

    padding: cRem(16);

    background-color: $main-black;

    > img {
      width: 100%;
    }

    &.show {
      animation: showAni 0.2s forwards;

      @keyframes showAni {
        from {
          opacity: 0.4;
        }
        to {
          opacity: 1;
        }
      }
    }

    &.hidden {
      animation: hiddenAni 0.2s forwards;

      @keyframes hiddenAni {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
          display: none;
        }
      }
    }
  }
}
