@import 'styles/import';

.parking_location_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // width: cRem(220);
  width: 60%;

  height: cRem(66);
  padding: cRem(12);
  gap: cRem(4);

  border-radius: cRem(12);
  background: $gray-f9;

  .tenant_name {
    text-align: center;

    color: $gray-44;
    font-size: cRem(18);
    font-style: normal;
    font-weight: 600;
    line-height: cRem(24);
    letter-spacing: -0.36px;

    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .info_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
