@import 'styles/import';

.list {
  width: 100%;

  height: calc(cvh(100) - cRem(80));
  overflow-y: auto;

  padding-top: cRem(8);
  padding-bottom: cRem(20);

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: cRem(12);
}
