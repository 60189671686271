@import 'styles/import';

.tag {
  background: #f9f9f9;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: cRem(3) cRem(6);
  gap: 10px;

  .name {
    color: var(--Text-Gray99, #999);
    font-size: cRem(10);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
