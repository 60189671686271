@import 'styles/import';

.pages {
  display: flex;
  flex-direction: column;

  width: 100vw;
  height: calc(cvh(100) - cRem(72)); // page - footer
}

.customMenu {
  width: 100vw;
  height: cvh(100);

  position: absolute;
  z-index: 100;

  transition: all 0.3s ease-out;

  &.open {
    animation: fadeIn 0.6s forwards;

    @keyframes fadeIn {
      0% {
        right: cRem(-200);
      }

      100% {
        right: cRem(0);
      }
    }
  }
}

@include slideAni(100%, 120%);

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: cRem(30);

  width: 100vw;
  height: cvh(100);

  background-color: $gray-f9;

  .kio_icon {
    width: cRem(200);
    height: cRem(200);
  }

  .refresh_btn {
    border: cRem(1) solid $main-black;
    width: 60%;
    padding: cRem(10);
    font-size: cRem(13);
  }
}
