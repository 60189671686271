@import 'styles/import';

.popup {
  height: cRem(234);

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: cRem(16);

  .content_wrapper {
    width: 100%;

    padding: cRem(24) cRem(20);
    padding-bottom: 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    gap: cRem(16);

    .message {
      color: $gray-44;
      text-align: center;
      font-family: 'Pretendard';
      font-size: cRem(16);
      font-style: normal;
      font-weight: 600;
      line-height: cRem(20);
      letter-spacing: -0.32px;
    }
  }

  .button_wrapper {
    width: 100%;
    height: cRem(72);

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
