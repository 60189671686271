@import 'styles/import';

.container {
  width: 100%;
  height: 100%;
  min-height: calc(cvh(100) - cRem(72));

  display: flex;
  flex-direction: column;
}
