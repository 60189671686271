@import 'styles/import';

.header {
  position: fixed;
  top: cRem(44);
  left: 0;

  z-index: 50;

  @include flexCenter;

  width: 100%;
  height: cRem(80);
  padding: 0 cRem(20) 0 cRem(16);

  border-bottom: 1px solid #222;
}

.field_wrapper {
  display: flex;
  align-items: center;

  height: 100%;
  width: 100%;

  gap: cRem(8);

  .search_icon {
    display: flex;
    justify-content: center;
    align-items: center;

    padding-left: cRem(4);

    svg {
      width: cRem(18);
      height: cRem(18);
    }
  }

  .before_icon {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: cRem(40);
      height: cRem(40);
    }
  }

  .input_wrapper {
    flex: 1;
    display: flex;

    .search_input {
      width: 100%;

      font-size: cRem(16);
      font-weight: 500;
      line-height: cRem(20); /* 125% */
      letter-spacing: cRem(-0.32);
      color: #444;

      &::placeholder {
        color: #bfbfbf;
      }
    }
  }

  .circle_close_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    width: cRem(24);
    height: cRem(24);
    padding: cRem(3) cRem(3) cRem(3) cRem(4);

    border-radius: cRem(12);
    border: cRem(1) solid #ccc;

    background: #ccc;

    svg {
      width: cRem(20);
      height: cRem(20);
    }
  }
}

.keyword_wrapper {
  display: flex;
  justify-content: space-between;

  width: 100%;

  .keyword {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: cRem(8) cRem(16);

    border-radius: cRem(4);
    border: cRem(1) solid #4f74f9;

    color: #777;
    font-size: cRem(16);
    font-weight: 500;
    line-height: cRem(20); /* 125% */
    letter-spacing: cRem(-0.32);
  }

  .close_icon {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: cRem(40);
      height: cRem(40);
    }
  }
}
