@import 'styles/import';

.button_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .divider {
    background-color: $gray-ea;
    width: cRem(1);
    height: 100%;
  }

  .button {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: cRem(4);
    background-color: white;

    > span {
      font-size: cRem(12);
      font-weight: 500;
      line-height: cRem(16);
      color: $gray-44;
      letter-spacing: -0.168px;
    }
  }
}

// 공유하기
.twinkle_container {
  position: relative;
  display: flex;
  justify-content: center;
  .text {
    font-size: cRem(12);
    font-weight: 500;
    line-height: cRem(16);
    color: $gray-44;
    letter-spacing: -0.168px;
    z-index: 1;
  }
  .twinkle {
    position: absolute;
    width: cRem(47);
    height: cRem(10);
    bottom: cRem(0);
    background: #a3eaef;
    // animation: slideAnimation 1s linear infinite;
    // opacity: 0.8; /* 반짝임을 강조하려면 약간의 투명도를 줄 수 있습니다 */
  }
}

/* 왼쪽에서 오른쪽으로 이동하는 애니메이션 정의 */
// @keyframes slideAnimation {
//   0% {
//     transform: translateX(-100%); /* 시작 위치: 왼쪽 밖에서 시작 */
//     opacity: 0;
//   }
//   50% {
//     opacity: 1; /* 가운데에서 가장 명확히 보이도록 */
//   }
//   100% {
//     transform: translateX(100%); /* 끝 위치: 오른쪽 밖으로 이동 */
//     opacity: 0;
//   }
// }
