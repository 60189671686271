@import 'styles/import';

.close_btn {
  display: flex;
  height: 36px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 18px;
  border: 1px solid var(--gray-01---EAEAEA, #eaeaea);
  background: var(--bg---F9F9F9, #f9f9f9);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);

  .close_text {
    color: var(--gray-06---222222, #222);
    text-align: center;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.28px;
  }
}
