@import 'styles/import';

.loader_wrapper {
  width: 100%;
  height: cRem(72);

  @include flexColumnCenter;

  .loader {
    width: cRem(20);
    height: cRem(20);

    border: cRem(2) solid $gray-cc;
    border-bottom-color: transparent;
    border-radius: 100%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
